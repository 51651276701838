import React, { useContext, useRef, useState } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import style from "./style.scss";
import logo from "../../../assets/img/hb/Isologotipo.png";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import {
  emailValidator,
  nameLastnameValidator,
  nameLastnameValidatorSpace,
  passportValidation,
} from "../../../utils/hooks/regex";
import Swal from "sweetalert2";
import axios from "axios";

import { fetchLogs } from "../../../utils/fetch";
import CommonRegisterConfirmation from "../../../components/common/register-confirmation";
import Overlay from "../../../components/common/overlay";
import Calendar from "react-calendar";
import CalendarModal from "../../../components/common/calendar-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import isOver13 from "../../../hooks/userBirthday";
import bgImageLogin from "./../../../assets/img/hb/image-login.jpg";
import bgImageLoginPSHB from "./../../../assets/img/hb/test2.jpg";
import useProgressiveImage from "../../../hooks/useProgressiveImage";
import { useToggle } from "../../../utils/hooks/useToggle";
import { TerminosCondicionesComponent } from "../../../components/common/footer/terminos-condiciones";

const RegisterHB = (props) => {
  const navigate = useNavigate();
  const loadedBgImage = useProgressiveImage(bgImageLogin);
  const terminosToggle = useToggle();

  const date = new Date();
  // const [maxDate, setMaxDate] = useState(`${date.getFullYear()}-${((date.getMonth()+1).toString() == 1 ? `0${date.getMonth()+1}` : `${date.getMonth()+1}`)}-${date.getUTCDate()}`)
  const [maxDate, setMaxDate] = useState(
    `${date.getFullYear()}-${
      (date.getMonth() + 1).toString().length == 1
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`
    }-${
      date.getUTCDate().toString().length == 1
        ? `0${date.getUTCDate()}`
        : `${date.getMonth()}`
    }`
  );

  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);
  const [loader, setLoader] = useState(false);
  const [toggleValue, setToggleValue] = useState(false);
  const [dateType, setDateType] = useState("text");

  const [searchParams, setSearchParams] = useSearchParams({});
  const pin = searchParams.get("pin");
  const emailParam = searchParams.get("email");

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [docType, setDocType] = useState("");
  const [documento, setDocumento] = useState("");
  const [genero, setGenero] = useState("");
  const [fechaNac, setFechaNac] = useState("");
  const [telefono, setTelefono] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [credencial, setCredencial] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [acepto, setAcepto] = useState(false);

  const [nombrePlaceholder, setNombrePlaceholder] = useState("");
  const [apellidoPlaceholder, setApellidoPlaceholder] = useState("");
  const [docTypePlaceholder, setDocTypPlaceholder] = useState("");
  const [documentoPlaceholder, setDocumentoPlaceholder] = useState("");
  const [generoPlaceholder, setGeneroPlaceholder] = useState("");
  const [fechaNacPlaceholder, setFechaNacPlaceholder] = useState("");
  const [telefonoPlaceholder, setTelefonoPlaceholder] = useState("");
  const [celularPlaceholder, setCelularPlaceholder] = useState("");
  const [emailPlaceholder, setEmailPlaceholder] = useState("");
  const [credencialPlaceholder, setCredencialPlaceholder] = useState("");
  const [aceptoPlaceholder, setAceptoPlaceholder] = useState("");
  const [errorMsgPlaceholder, setErrorMsgPlaceholder] = useState("");
  const refFecha = useRef(null);

  const [showModal, setShowModal] = useState(false);

  const cleanRedPlaceholder = () => {
    setNombrePlaceholder("");
    setApellidoPlaceholder("");
    setDocTypPlaceholder("");
    setDocumentoPlaceholder("");
    setGeneroPlaceholder("");
    setFechaNacPlaceholder("");
    setTelefonoPlaceholder("");
    setCelularPlaceholder("");
    setEmailPlaceholder("");
    setCredencialPlaceholder("");
    setErrorMsgPlaceholder("");
    setApellidoPlaceholder("");
    setAceptoPlaceholder("");
  };

  const cleanForm = () => {
    setNombre("");
    setApellido("");
    // setDocType("");
    // setGenero("");
    setDocumento("");
    setFechaNac("");
    setTelefono("");
    setCelular("");
    setEmail("");
    setCredencial("");
    setErrorMsg("");
    setAcepto(false);
  };

  const checkRegister = () => {
    !nombre
      ? setNombrePlaceholder("red-placeholder")
      : setNombrePlaceholder("");
    !apellido
      ? setApellidoPlaceholder("red-placeholder")
      : setApellidoPlaceholder("");
    !docType
      ? setDocTypPlaceholder("red-placeholder-select")
      : setDocTypPlaceholder("");
    !documento
      ? setDocumentoPlaceholder("red-placeholder")
      : setDocumentoPlaceholder("");
    !genero
      ? setGeneroPlaceholder("red-placeholder-select")
      : setGeneroPlaceholder("");
    // !fechaNac ? setFechaNacPlaceholder("red-placeholder") : setFechaNacPlaceholder("");
    // if(isNaN(Date.parse(fechaNac))){
    //     setFechaNacPlaceholder("red-placeholder");
    //     setFechaNac("");
    // }else{
    //     setFechaNacPlaceholder("");
    // }
    !fechaNac
      ? setFechaNacPlaceholder("red-placeholder")
      : setFechaNacPlaceholder("");
    !telefono
      ? setTelefonoPlaceholder("red-placeholder")
      : setTelefonoPlaceholder("");
    !celular
      ? setCelularPlaceholder("red-placeholder")
      : setCelularPlaceholder("");
    !email ? setEmailPlaceholder("red-placeholder") : setEmailPlaceholder("");
    !credencial
      ? setCredencialPlaceholder("red-placeholder")
      : setCredencialPlaceholder("");
    !acepto
      ? setAceptoPlaceholder("red-placeholder")
      : setAceptoPlaceholder("");
    setErrorMsg("* Por favor complete los datos obligatorios");
  };

  const handlerDate = () => {
    let plainDate = fechaNac.replace(/-/g, "");
    let year = plainDate.slice(0, 4);
    let month = plainDate.slice(4, 6);
    let day = plainDate.slice(6, 8);
    let newDate = `${day}-${month}-${year}`;

    newDate.length == 10 ? setFechaNac(newDate) : setFechaNac("");
  };

  const handlerRegiserHB = (event) => {
    event.preventDefault();
    // console.log(nombre, apellido, docType, documento, genero, fechaNac, telefono, celular, email, credencial)
    setErrorMsg("");

    if (
      !nombre ||
      !apellido ||
      !docType ||
      !documento ||
      !genero ||
      !fechaNac ||
      !celular ||
      !email ||
      (toggleValue && !credencial) ||
      !acepto
    ) {
      checkRegister();
    } else {
      if (emailValidator(email)) {
        setEmailPlaceholder("red-input-border");
        setErrorMsg("* Por favor ingresa un formato de email valido");
      } else if (!isOver13(fechaNac)) {
        setErrorMsg(
          "* Fecha de nacimiento inválida. Debe ser mayor a 13 años."
        );
      } else {
        setEmailPlaceholder("");
        setAceptoPlaceholder("");
        setLoader(true);
        /* LLAMADO */

        let pshbContrat = credencial.substring(0, credencial.length - 2);
        let benNumber = credencial.slice(-2);
        let credentialNum = `${pshbContrat} ${benNumber}`;
        let formatDate = `${fechaNac.slice(-2)}-${fechaNac.slice(
          5,
          7
        )}-${fechaNac.slice(0, 4)}`;

        let body = {
          firstName: nombre,
          lastName: apellido,
          email: email,
          birthday: fechaNac,
          phone: telefono,
          mobile: celular,
          password: "",
          socialSecurity: credencial ? "40080/000" : "",
          socialSecurityNumber: credencial ? credentialNum : "",
          gender: genero,
          dni: documento,
          socialSecurityPlan: "",
          dniType: docType,
          cellCompany: "0",
          acceptedPolicies: true,
          ivaTax: "0",
          pshb: credencial ? true : false,
        };

        axios({
          url:
            process.env.REACT_APP_STRAPI_QA +
            process.env.REACT_APP_STRAPI_QA_ENDPOINT_REGISTER,
          method: "POST",
          data: body,
        })
          .then((res) => {
            if (res.data.responseCode && res.data.responseCode == 200) {
              setLoader(false);
              cleanForm();
              cleanRedPlaceholder();
              Swal.fire({
                icon: "success",
                title: '<h5 style="color: #224d7c">Registro exitoso</h5>',
                text: res.data.message
                  ? res.data.message
                  : "Por favor revisa tu casilla de correo electronico para completar el registro.",
                color: "#224d7c",
                confirmButtonColor: "#224d7c",
                confirmButtonText: "Continuar",
              });
              try{
                fetchLogs({
                  idLogType: 1,
                  idLog: 1,
                  source: "hb-online",
                  email: email,
                  dni: documento,
                  credencial: credencial ?? "",
                  description:
                    "El usuario " +
                    res.data.data.LastName +
                    " " +
                    res.data.data.FirstName +
                    " se ha registrado en el Portal Web con email " +
                    res.data.data.user,
                });
              } catch (logError) {
                console.error("Error al registrarse en portal web:", logError);
              }
              const redirect = sessionStorage.getItem("redirect");
              if (redirect) {
                sessionStorage.removeItem("redirect");
                navigate(redirect, { replace: true });
              } else {
                navigate("/");
              }
            } else if (res.data.responseCode && res.data.responseCode == 400) {
              setLoader(false);
              cleanForm();
              cleanRedPlaceholder();
              Swal.fire({
                icon: "warning",
                title: '<h5 style="color: #224d7c"></h5>',
                text: res.data.message
                  ? res.data.message
                  : "Detectamos un error en el proceso de registro; por favor comunicate con nuestro equipo de soporte.",
                color: "#224d7c",
                confirmButtonColor: "#224d7c",
                confirmButtonText: "Continuar",
              });
            }else if(res.data.responseCode === 401){
              setLoader(false);
              cleanForm();
              cleanRedPlaceholder();
              Swal.fire({
                icon: "warning",
                title: '<h5 style="color: #224d7c"></h5>',
                text: res.data.message,                  
                color: "#224d7c",
                confirmButtonColor: "#224d7c",
                confirmButtonText: "Continuar",
              });
            } else {
              setLoader(false);
              cleanForm();
              cleanRedPlaceholder();
              Swal.fire({
                icon: "error",
                title: '<h5 style="color: #224d7c"></h5>',
                text: "Detectamos un error en el proceso de registro; por favor comunicate con nuestro equipo de soporte.",
                color: "#224d7c",
                confirmButtonColor: "#224d7c",
                confirmButtonText: "Continuar",
              });
            }
          })
          .catch((err) => {
            setLoader(false);
            cleanForm();
            cleanRedPlaceholder();
            Swal.fire({
              icon: "error",
              title: '<h5 style="color: #224d7c"></h5>',
              text: "Detectamos un error en el proceso de registro; por favor comunicate con nuestro equipo de soporte.",
              color: "#224d7c",
              confirmButtonColor: "#224d7c",
              confirmButtonText: "Continuar",
            });
          });
      }
    }
  };

  const handleOnChangeDocType = (value) => {
    setDocType(value);
    setDocumento("");
  };

  return (
    <>
      {loader ? <Overlay /> : null}
      {pin && emailParam ? (
        <CommonRegisterConfirmation
          pin={pin}
          emailParam={emailParam}
          pshb={false}
        />
      ) : (
        <section>          
          <TerminosCondicionesComponent
                  visible={terminosToggle.toggle}
                  onToggle={terminosToggle.onToggle}
                />          
          <form onSubmit={handlerRegiserHB}>
            <div
              className="d-flex align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="div-register">
                <div className="d-flex flex-column align-items-center">
                  <img className="img-logo" src={logo}></img>
                  <div className="br"></div>
                  <h5 className="default-fontColor">Registro</h5>
                  <div className="br"></div>
                  <div className="register-box">
                    <div className="register-row-2">
                      <input
                        maxLength={50}
                        onChange={(e) =>
                          setNombre(
                            !nombre
                              ? nameLastnameValidator(e.target.value)
                              : nameLastnameValidatorSpace(e.target.value)
                          )
                        }
                        value={nombre}
                        className={`input-register shadow-blue ${nombrePlaceholder} input-login-v2`}
                        placeholder="Nombre"
                      ></input>
                      <div className="br"></div>
                      <input
                        maxLength={50}
                        onChange={(e) =>
                          setApellido(
                            !apellido
                              ? nameLastnameValidator(e.target.value)
                              : nameLastnameValidatorSpace(e.target.value)
                          )
                        }
                        value={apellido}
                        className={`input-register shadow-blue ${apellidoPlaceholder} input-login-v2`}
                        placeholder="Apellido"
                      ></input>
                    </div>
                    <div className="br"></div>
                    <div className="register-row-2">
                      <select
                        onClick={() => setDocTypPlaceholder("")}
                        onChange={(e) => handleOnChangeDocType(e.target.value)}
                        className={`option-register shadow-blue ${docTypePlaceholder} input-login-v2`}
                      >
                        <option className="option-default-size" value="" hidden>
                          Tipo de Documento
                        </option>
                        <option value="0">DNI</option>
                        <option value="2">Pasaporte</option>
                      </select>
                      <div className="br"></div>
                      <input
                        maxLength={docType == "2" ? 20 : 8}
                        onChange={(e) =>
                          docType == "2"
                            ? setDocumento(passportValidation(e.target.value))
                            : setDocumento(
                                e.target.value.replace(/[^0-9]/g, "")
                              )
                        }
                        value={documento}
                        className={`input-register shadow-blue ${documentoPlaceholder} input-login-v2`}
                        placeholder="N° de Documento"
                      ></input>
                    </div>
                    <div className="br"></div>
                    <div className="register-row-2">
                      <select
                        onClick={() => setGeneroPlaceholder("")}
                        onChange={(e) => setGenero(e.target.value)}
                        className={`option-register shadow-blue ${generoPlaceholder} input-login-v2`}
                      >
                        <option className="option-default-size" value="" hidden>
                          Género
                        </option>
                        <option value="M">Mujer</option>
                        <option value="H">Hombre</option>
                      </select>
                      <div className="br"></div>
                      <input
                        type="text"
                        ref={refFecha}
                        onClick={() => {
                          setFechaNac("");
                          setShowModal(true);
                        }}
                        //onBlur={()=>setShowModal(false)}
                        // onBlur={()=>{
                        //     setDateType("text")
                        //     handlerDate()}
                        //     }
                        // data-date-format="DD-MM-YYYY"
                        value={fechaNac}
                        min="1900-01-01"
                        max={maxDate}
                        className={`input-register shadow-blue ${fechaNacPlaceholder} input-login-v2`}
                        placeholder="Fecha de Nacimiento"
                        // pattern="\d{2}-\d{2}-\d{4}"
                        // onChange={(e)=>{
                        //     // e.preventDefault()
                        //     setShowModal(true)
                        //     }}
                      >
                        {/* <FontAwesomeIcon style={{right:"1.3vw",position:"absolute"}}  icon={faCalendar}/>                                 */}
                      </input>
                      {/* <div className={`input-register shadow-blue ${fechaNacPlaceholder}`} style={{display:"flex",alignItems:"center",position:"relative"}}>
                                            <input 
                                                // className="custom-input-login"
                                                type="text" 
                                                ref={refFecha}
                                                onFocus={()=>setShowModal(true)}
                                                onBlur={()=>setDateType("text")}
                                                className={`custom-input-login ${fechaNacPlaceholder}`} 
                                                onChange={(e)=>e.preventDefault()}
                                                placeholder="Fecha de Nacimiento"
                                            >
                                            </input> 
                                            <FontAwesomeIcon style={{right:"2vw",position:"absolute"}} icon={faCalendar} />                                
                                        </div> */}
                    </div>
                    <div className="br"></div>
                    <div className="register-row-2">
                      <input
                        maxLength={15}
                        onChange={(e) =>
                          setTelefono(e.target.value.replace(/[^0-9]/g, ""))
                        }
                        value={telefono}
                        className={`input-register shadow-blue ${telefonoPlaceholder} input-login-v2`}
                        placeholder="Teléfono de Línea"
                      ></input>
                      <div className="br"></div>
                      <input
                        maxLength={15}
                        onChange={(e) =>
                          setCelular(e.target.value.replace(/[^0-9]/g, ""))
                        }
                        value={celular}
                        className={`input-register shadow-blue ${celularPlaceholder} input-login-v2`}
                        placeholder="Celular"
                      ></input>
                    </div>
                    <div className="br"></div>
                    <div className="register-row">
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className={`input-register shadow-blue ${emailPlaceholder} input-login-v2`}
                        placeholder="E-mail"
                        style={{maxHeight:"42px"}}
                      ></input>
                      <div className="br"></div>
                      <div className="toggle-box d-flex align-items-center justify-content-between input-login-v2">
                        <h6
                          className="default-fontColor h6"
                          style={{ maxWidth: "75%" }}
                        >
                          ¿Posee Plan de Salud del Hospital Británico?
                        </h6>
                        <label className="switch">
                          <input
                            onChange={() =>
                              toggleValue
                                ? setToggleValue(false)
                                : setToggleValue(true)
                            }
                            checked={toggleValue}
                            type="checkbox"
                          />
                          <span
                            className={`slider round ${
                              toggleValue ? "slider-si" : "slider-no"
                            }`}
                          ></span>
                        </label>
                      </div>
                    </div>
                    {toggleValue ? (
                      <>
                        <div className="br"></div>
                        <div className="register-row">
                          <input
                            onChange={(e) =>
                              setCredencial(
                                e.target.value.replace(/[^0-9]/g, "")
                              )
                            }
                            value={credencial}
                            className={`input-register-credencial shadow-blue ${credencialPlaceholder}`}
                            placeholder="N° de Afiliado"
                          ></input>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="mt-4 form-check privacidad">
                    <input
                      name="aceptoTerminos"
                      checked={acepto}
                      onChange={() =>
                        acepto ? setAcepto(false) : setAcepto(true)
                      }
                      className={`form-check-input ${aceptoPlaceholder}`}
                      type="checkbox"
                      id="defaultCheck1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="defaultCheck1"
                      id="politicas-hb"
                    >
                      Acepto{" "}
                      <Link to={""} onClick={terminosToggle.onToggle}>
                        {" "}
                        términos y condiciones
                      </Link>
                    </label>
                    {aceptoPlaceholder ? (
                      <div style={{ color: "red", fontWeight: "500" }}>
                        Campo obligatorio
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="br"></div>
                  {/* <Link to={<>null</>} className='link-noStyle'></Link> */}
                  <div className="br"></div>
                  {loader ? (
                    <button
                      disabled={true}
                      type="submit"
                      className="button-loader shadow-blue"
                    >
                      <span className="spinner-grow spinner-grow-sm"></span>
                      <span className="spinner-grow spinner-grow-sm"></span>
                      <span className="spinner-grow spinner-grow-sm"></span>
                    </button>
                  ) : (
                    <input
                      className="button-continuar shadow-blue"
                      type="submit"
                      value="Continuar"
                    />
                  )}
                  {/* <div className="br"></div> */}                  
                  <div
                        className="button-outline-parent mt-2"
                        style={{ position: "relative" }}
                      >
                        <Link to={"/"} className="button-outline-login">
                          <div className="button-outline-login-contain"></div>
                          Volver al inicio
                        </Link>
                      </div>

                      <p style={{ color: "red", fontWeight: "500" }} className="br">
                    {errorMsg}
                  </p>
                </div>
              </div>
              <div
                className="bg-image"
                style={{ backgroundImage: `url(${loadedBgImage || ""})` }}
              >
                <div className="bg-image-text">
                  <div className="bg-image-text">
                    <div className="transbox">
                      <h1>Bienvenido a mi HB Online</h1>
                      <h5>
                        Solicitá un turno, accedé a tus resultados, gestioná tu
                        grupo familiar y tu perfil
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      )}
      {showModal ? (
        <>
          <CalendarModal
            setFechaNac={setFechaNac}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default RegisterHB;
