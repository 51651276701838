import React, { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./cbu.scss";
import CustomSelect from "../../../common/customSelect";
import { CUSTOM_SELECT_COLOR_WHITE } from "../../../common/customSelect/constants";
import { useFormik } from "formik";
import validate from "./validate";
// import axios from "axios";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { myAxios, fetchLogs } from "../../../../utils/fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

var tipoCuentaList = [
  { id: "Cuenta Corriente", name: "Cuenta Corriente" },
  { id: "Caja de Ahorro", name: "Caja de Ahorro" },
  { id: "Cuenta Sueldo", name: "Cuenta Sueldo" },
  { id: "Cuenta Especial", name: "Cuenta Especial" },
];

export const CBUComponent = ({
  visible = false,
  onToggle,
  obtenerCBUS,
  banks,
  integranteSelected,
  contrato,
}) => {
  const [tipoCuenta, setTipoCuenta] = React.useState("Seleccione Tipo Cuenta");
  const { user } = useContext(UserGlobalContextMemorySpace);
  //const [bancoList,setBancoList]=React.useState([]);
  const [banco, setBanco] = React.useState("Seleccione un Banco");
  const [loading, setLoading] = React.useState(false);

  //CLOSE
  const [closeTipoCuenta, setCloseTipoCuenta] = React.useState(false);
  const [closeBanco, setCloseBanco] = React.useState(false);
  //END CLOSE

  const [errorMsg, setErrorMsg] = useState();
  const fileTypes = ["application/pdf", "image/png", "image/jpg", "image/jpeg"];
  const [fileSelected, setFile] = React.useState({ label: "", error: false });
  const [archivoDU, setArchivoDU] = React.useState(null);
  const [error, setError] = useState("");

  const controller = new AbortController();

  //CLICK
  const clickTipoCuenta = () => {
    setCloseBanco(true);
    setCloseTipoCuenta(false);
  };

  const clickBanco = () => {
    setCloseBanco(false);
    setCloseTipoCuenta(true);
  };
  //END CLICK

  React.useEffect(() => {}, [integranteSelected]);

  const cambiarTipoCuenta = (id, name, text) => {
    setTipoCuenta(text);
    formik.setFieldValue("tipoCuenta", text);
  };

  const cambiarBanco = (id, name, text) => {
    setBanco(text);
    formik.setFieldValue("banco", text);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const seleccionarArchivo = () => {
    setErrorMsg("");
    var input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg,.jpeg,.png,.pdf";
    input.onchange = async (e) => {
      var file = e.target.files[0];

      if (fileTypes.indexOf(file.type) === -1) {
        setFile({
          label: "",
          error: true,
          errorMessage: "Solo se permiten imagenes JPG,JPEG,PNG y PDF",
        });
        setErrorMsg("Solo se permiten imagenes JPG,JPEG,PNG y PDF.");
        setArchivoDU(null);
        return;
      }

      if (file.size > 3000000) {
        setFile({
          label: "",
          error: true,
          errorMessage: "Por favor verifica que tu archivo pese menos de 3 MB",
        });
        setErrorMsg("Por favor verifica que tu archivo pese menos de 3 MB");
        setArchivoDU(null);
        return;
      }

      try {
        const result = await toBase64(file);
        let aux = [];
        aux = result.split(",");
        setFile({ label: file.name, error: false, file: file, base64: aux[1] });
        setArchivoDU(aux[1]);
        formik.setFieldValue("constancia", aux[1]);
      } catch (error) {
        setFile({
          label: "Error",
          error: true,
          errorMessage:
            "Detectamos un error, por favor intenta nuevamente en unos minutos.",
        });
        setErrorMsg(
          "Detectamos un error, por favor intenta nuevamente en unos minutos."
        );
        setArchivoDU(null);
        return;
      }
    };

    input.click();
  };

  const formik = useFormik({
    initialValues: {
      contra: user.user.data.Customers.credencial.split(" ")[0],
      vigenDesde: "",
      cbu: "",
      cuit: "",
      refCuenta: "",
      alias: "",
      tipoCuenta: "",
      nroCuenta: "",
      banco: "",
      titularCuenta: "",
      // idCustomer:"",
      vinculo: "",
      constancia: "",
      constanciaName: "",
      fullname: integranteSelected ? integranteSelected : "",
    },

    validate,

    onSubmit: (values) => {
      setLoading(true);

      var now = new Date();
      values.vigenDesde =
        now.getFullYear() +
        "" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "" +
        now.getDate().toString().padStart(2, "0");
      values.alias = "";
      values.cbu = values.cbu.replaceAll("-", "");
      // values.idCustomer = user.user.data.Customers.idCustomer;
      values.constanciaName = fileSelected.file.name;
      values.contrato = contrato;

      myAxios({
        signal: controller.signal,
        url:
          process.env.REACT_APP_GATEWAY_QA +
          "/PSAfiliates/Api/v1/SetContractCbuRediseno",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          USERTOKEN: user.user.data.Token,
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
        data: values,
      })
        .then((response) => {
          if (response.data.responseCode === 200) {
            onToggle();
            obtenerCBUS();
            try {
              fetchLogs({
                idLogType: 100,
                idLog: 203,
                source: "hb-online",
                email: user.user.data.Customers.email,
                dni: user.user.data.Customers.dni ?? "",
                credencial: user.user.data.Customers.credencial ?? "",
                description:
                  "El usuario " +
                  user.user.data.Customers.lastName +
                  " " +
                  user.user.data.Customers.firstName +
                  " cargó correctamente el CBU " +
                  values.cbu +
                  " con la referencia " +
                  values.refCuenta +
                  ".",
              });
            } catch (logError) {
              console.error("Error al cargar CBU:", logError);
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const cambiarCuit = (e) => {
    const { value } = e.target;
    if ((value.length === 2 || value.length === 11) && e.keyCode !== 45) {
      e.target.value = value + "-";
    }

    const regexNumber = /^[0-9]$/;
    if (!regexNumber.test(value.at(-1)) && e.keyCode !== 45) {
      e.target.value = value.substring(0, value.length - 1);
    }

    formik.handleChange(e);
  };

  const tooltipCuit = (props) => (
    <Tooltip {...props}>(Solo se permiten valores numericos)</Tooltip>
  );

  const tooltipVinculo = (props) => (
    <Tooltip {...props}>
      Texto libre para que usted pueda identificar el Vínculo con el CBU a
      cargar.
    </Tooltip>
  );

  const tooltipReferencia = (props) => (
    <Tooltip {...props}>
      Texto libre para que usted pueda identificar a cuál cuenta corresponde el
      CBU a cargar.
    </Tooltip>
  );

  return (
    <Modal
      size={"lg"}
      aria-labelledby="contained-modal-title-vcenter"
      show={visible}
      centered
      onHide={onToggle}
    >
      <Modal.Header closeButton className="custom-header">
        <div className="simple-header">
          <h2 class="section_hb_title">
            <b>Editar información de pago</b>
          </h2>
          <div class="separador-table-green-result w-100"></div>
        </div>
      </Modal.Header>

      <Modal.Body className="custom-modal-body">
        <form onSubmit={formik.handleSubmit}>
          <div className="custom-container">
            <div className="row">
              <div className="section-date col-md-12 col-lg-6">
                <span className={"label-pshb"}>
                  Titular de la cuenta <span className="obligatorio">*</span>
                </span>
                <div className="input-icons-date-pshb-refund d-flex flex-column">
                  <input
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.titularCuenta}
                    name="titularCuenta"
                    className={
                      (formik.errors.titularCuenta &&
                      formik.touched.titularCuenta
                        ? "error-data "
                        : "") + "input-round input-text-pshb shadow-blue w-100"
                    }
                  />
                  {formik.touched.titularCuenta ? (
                    <span className="error-label-pshb">
                      {formik.errors.titularCuenta}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="section-date col-md-12 col-lg-6">
                <span className="label-pshb">
                  CBU <span className="obligatorio">*</span>
                </span>
                <div className="input-icons-date-pshb-refund d-flex flex-column">
                  <input
                    type="text"
                    maxLength={22}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cbu}
                    name="cbu"
                    className={
                      (formik.errors.cbu && formik.touched.cbu
                        ? "error-data "
                        : "") + "input-round input-text-pshb shadow-blue w-100"
                    }
                  />
                  {formik.touched.cbu ? (
                    <span className="error-label-pshb">
                      {formik.errors.cbu}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="section-date col-md-12 col-lg-6">
                <span className="label-pshb">
                  N° de CUIL - CUIT Titular{" "}
                  <span className="obligatorio">*</span>
                </span>
                <div className="input-icons-date-pshb-refund d-flex flex-column">
                  <OverlayTrigger placement="top" overlay={tooltipCuit}>
                    <input
                      type="text"
                      data-tip="Solo se permiten valores númericos"
                      placeholder="00-00000000-0"
                      name="cuit"
                      maxLength={13}
                      onChange={(e) => cambiarCuit(e)}
                      onBlur={formik.handleBlur}
                      value={formik.values.cuit}
                      className={
                        (formik.errors.cuit && formik.touched.cuit
                          ? "error-data "
                          : "") +
                        "input-round input-text-pshb shadow-blue w-100"
                      }
                    />
                  </OverlayTrigger>
                  {formik.touched.cuit ? (
                    <span className="error-label-pshb">
                      {formik.errors.cuit}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="section-date col-md-12 col-lg-6">
                <span className="label-pshb">
                  N° de cuenta <span className="obligatorio">*</span>
                </span>
                <div className="input-icons-date-pshb-refund d-flex flex-column">
                  <input
                    onPaste={(e) => e.preventDefault()}
                    type="text"
                    name="nroCuenta"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nroCuenta}
                    className={
                      (formik.errors.nroCuenta && formik.touched.nroCuenta
                        ? "error-data "
                        : "") + "input-round input-text-pshb shadow-blue w-100"
                    }
                  />
                  {formik.touched.nroCuenta ? (
                    <span className="error-label-pshb">
                      {formik.errors.nroCuenta}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="section-date col-md-12 col-lg-6">
                <span className="label-pshb">
                  Tipo de cuenta <span className="obligatorio">*</span>
                </span>
                <div className="input-icons-date-pshb-refund relative h-30">
                  <CustomSelect
                    defaultText={tipoCuenta}
                    optionsList={tipoCuentaList}
                    type={CUSTOM_SELECT_COLOR_WHITE}
                    fontFamily="Roboto-Medium"
                    width="100%"
                    fontSize="14px"
                    onChange={cambiarTipoCuenta}
                    error={
                      formik.errors.tipoCuenta && formik.touched.tipoCuenta
                    }
                    name="tipoCuenta"
                    close={closeTipoCuenta}
                    onClick={clickTipoCuenta}
                  />
                </div>
                {formik.touched.tipoCuenta ? (
                  <span className="error-label-pshb">
                    {formik.errors.tipoCuenta}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="section-date col-md-12 col-lg-6">
                <span className="label-pshb">Referencia de cuenta</span>
                <div className="input-icons-date-pshb-refund ">
                  <OverlayTrigger placement="top" overlay={tooltipReferencia}>
                    <input
                      onPaste={(e) => e.preventDefault()}
                      type="text"
                      data-tip="Texto libre para que usted pueda identificar a cual cuenta corresponde el CBU a cargar"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.refCuenta}
                      name="refCuenta"
                      class="input-round input-text-pshb shadow-blue w-100"
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="section-date col-md-12 col-lg-6">
                <span className={"label-pshb"}>
                  Vínculo con el Socio <span className="obligatorio">*</span>
                </span>
                <div className="input-icons-date-pshb-refund d-flex flex-column">
                  <OverlayTrigger placement="top" overlay={tooltipVinculo}>
                    <input
                      placeholder={"Vínculo con el Socio"}
                      type="text"
                      maxLength={10}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.vinculo}
                      name="vinculo"
                      className={
                        (formik.errors.vinculo && formik.touched.vinculo
                          ? "error-data "
                          : "") +
                        "input-round input-text-pshb shadow-blue w-100"
                      }
                    />
                  </OverlayTrigger>
                  {formik.touched.vinculo ? (
                    <span className="error-label-pshb">
                      {formik.errors.vinculo}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="section-date col-md-12 col-lg-6">
                <span className="label-pshb">
                  Constancia CBU <span className="obligatorio">*</span>
                </span>
                <div
                  className="input-icons-date-pshb-refund d-flex flex-column"
                  onClick={() => seleccionarArchivo()}
                >
                  <input
                    type="text"
                    maxLength={22}
                    placeholder={"Adjuntar"}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    value={
                      fileSelected.file && !fileSelected.error
                        ? fileSelected.label
                        : ""
                    }
                    name="constancia"
                    className={
                      (formik.errors.constancia && formik.touched.constancia
                        ? "error-data "
                        : "") + "input-round input-text-pshb shadow-blue w-100"
                    }
                  />
                  {formik.touched.constancia ? (
                    <span className="error-label-pshb">
                      {formik.errors.constancia}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
                {fileSelected.error ? (
                  <span
                    className={fileSelected.error ? "error-file" : ""}
                    style={{
                      marginLeft: "",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    {fileSelected.errorMessage}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row">
              <div className="section-date col-md-12 col-lg-12">
                <span className="label-pshb">
                  Banco <span className="obligatorio">*</span>
                </span>
                <div className="input-icons-date-pshb-refund relative h-20">
                  <CustomSelect
                    defaultText={banco}
                    optionsList={banks}
                    type={CUSTOM_SELECT_COLOR_WHITE}
                    fontFamily="Roboto-Medium"
                    width="100%"
                    fontSize="14px"
                    error={formik.errors.banco && formik.touched.banco}
                    onChange={cambiarBanco}
                    close={closeBanco}
                    onClick={clickBanco}
                  />
                </div>
                {formik.touched.banco ? (
                  <span
                    className="error-label-pshb"
                    style={{ marginTop: "10px" }}
                  >
                    {formik.errors.banco}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row">
              <div
                className="section-date col-md-12 col-lg-12"
                style={{ marginTop: "25px" }}
              >
                <center>
                  <span className="obligatorio">
                    Le recordamos que NO se aceptan CBUs de instituciones y/o
                    profesionales que brinden las prestaciones autorizadas. Solo
                    podrá informarse el CBU del afiliado o del familiar a cargo,
                    adjuntando constancia bancaria.
                  </span>
                </center>
              </div>
            </div>

            <div className="row custom-footer" style={{ paddingTop: "10px" }}>
              <div className='"col-md-12 text-center mb-5 mt-3'>
                <div
                  className="btn btn-hb-secondary-without-border"
                  onClick={() => onToggle()}
                >
                  Cancelar
                </div>
                {!loading ? (
                  <Button
                    type="submit"
                    className="btn btn-hb-primary-without-border shadow-blue"
                  >
                    Aceptar
                  </Button>
                ) : (
                  <>
                    <span className="spinner-grow spinner-grow-sm"></span>
                    <span className="spinner-grow spinner-grow-sm"></span>
                    <span className="spinner-grow spinner-grow-sm"></span>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>

        <div className="img-footer-modal-pshb"></div>
      </Modal.Body>

      {/* <Modal.Footer>                            
            </Modal.Footer> */}
    </Modal>
  );
};
