import React, { useContext } from "react";
import DownloadIcon from "../../../assets/img/card-icons/descargar.svg";
import PagarIcon from "../../../assets/img/hb/Facturacion.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCity, faSearch } from "@fortawesome/free-solid-svg-icons";
import "./invoices.scss";
import CustomSelect from "../../common/customSelect";
import {
  CUSTOM_SELECT_COLOR_BLUE,
  CUSTOM_SELECT_COLOR_WHITE,
} from "../../common/customSelect/constants";
import { useToggle } from "../../../hooks/useToggle";
import { InformacionComponent } from "./informacion";
import InformationIcon from "../../../assets/img/information.svg";
import axios from "axios";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { fetchLogs, myAxios } from "../../../utils/fetch";
import { useLocation, useSearchParams } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const InvoicesComponent = (props) => {
  const infoToggle = useToggle();
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);
  const [facturas, setFacturas] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams({});
  const openDialogParams = searchParams.get("open");

  React.useEffect(() => {
    const socialSecurityNumber = user.user.data.Customers.credencial || user.user.data.Customers.socialSecurityNumber;

    myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/PSAfiliates/api/v1/getFacturasporcredencial?socialSecurityNumber=" +
        socialSecurityNumber +
        "&idCustomer=" +
        user.user.data.Customers.idCustomer,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    })
      .then((response) => {
        linkPago(socialSecurityNumber.split(" ")[0])
          .then((result) => {
            let factura = response.data.data.ds.slice(0, 1);
            factura[0].link = result.data.data.link;
            setFacturas(factura);
          })
          .catch((err) => {
            let factura = response.data.data.ds.slice(0, 1);
            factura[0].link = null;
            setFacturas(factura);
          });
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (openDialogParams == "1") {
      infoToggle.onToggle();
    }
  }, []);

  const linkPago = async (socialSecurityNumber) => {
    let url = await myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        `/patient/api/v1/pagos/getlinkpago?credentialNumber=${socialSecurityNumber}&idCustomer=${user.user.data.Customers.idCustomer}`,
      headers: {
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    });

    return url;
  };

  const descargar = (factura) => {
    myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/psafiliates/api/v1/getfactura?idInvoice=" +
        factura.Nombre +
        "&type=" +
        factura["Tipo de documento"] +
        "&isCompany=false&idCustomer=" +
        user.user.data.Customers.idCustomer,
      headers: {
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
        responseType: "blob",
      },
    })
      .then((response) => {

        try {
          fetchLogs({
            idLogType: 1,
            idLog: 8,    
            source: "hb-online",
            email: user.user.data.Customers.email,    
            dni: user.user.data.Customers.dni ?? "",
            credencial: user.user.data.Customers.credencial ?? "",   
            description:"El usuario " + user.user.data.Customers.lastName + " " + user.user.data.Customers.firstName + " bajo la factura / nota credito "+factura.Nombre,                  
          });
        } catch (logError) {
          console.error("Error al bajar factura:", logError);
        }

        const linkSource = `data:application/pdf;base64,${response.data.data.datos}`;
        const downloadLink = document.createElement("a");
        const fileName = `${factura.Nombre}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((error) => {});
  };

  return (
    <section className=" ">
      <div className="container p-4">
        {/* TITULO */}
        <div className="row mt-5">
          <div className="col-lg-4">
            <h2 className="section_pshb_title mb-3">
              <b>Facturas</b>
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12" id="header-invoice">
            <span className="section_pshb_subtitle">
              En esta sección podrás descargar tu última factura.
            </span>
            <a data-tooltip-id="my-tooltip" data-tooltip-content="Ayuda">
              <ReactTooltip id="my-tooltip" />{" "}
              <img
                id="icon-information"
                onClick={infoToggle.onToggle}
                src={InformationIcon}
                alt="..."
                style={{height:"28px"}}
              ></img>
            </a>
            <InformacionComponent
              visible={infoToggle.toggle}
              onToggle={infoToggle.onToggle}
            />
          </div>
        </div>
        {/* SEPARADOR */}
        <div className="separador-table-green-result"></div>
        {/* TABLA */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="table-responsive">
              <table className="table table-pshb table-invoices">
                <thead>
                  <tr>
                    <th>Período</th>
                    <th>Tipo de documento</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading ? (
                    facturas ? (
                      facturas.map((factura, indiceFactura) => {
                        return (
                          <tr>
                            <td>{factura?.alta_fecha?.substring(0, 10)}</td>
                            <td>{factura["Tipo de documento"]}</td>
                            <td>
                              <img
                                className="pointer"
                                onClick={() => descargar(factura)}
                                src={DownloadIcon}
                                alt="..."
                              ></img>

                              {factura.link != null && factura.link != "" ? (
                                <a target={"_blank"} href={factura.link}>
                                  <img
                                    className="pointer"
                                    src={PagarIcon}
                                    alt="..."
                                  ></img>{" "}
                                </a>
                              ) : (
                                <img
                                  className="pointer"
                                  src={PagarIcon}
                                  alt="..."
                                  style={{opacity:"0.5"}}
                                ></img>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        <span className="spinner-grow spinner-grow-sm"></span>
                        <span className="spinner-grow spinner-grow-sm"></span>
                        <span className="spinner-grow spinner-grow-sm"></span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* SEPARADOR */}
        <div className="separador-table-green-result"></div>
      </div>      
    </section>
  );
};

export default InvoicesComponent;
