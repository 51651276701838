import DownloadIcon from "../../../../assets/img/card-icons/descargar.svg";
import MailIcon from "../../../../assets/img/card-icons/mail.svg";

import "../style.scss";
import "./index.scss";

import React, { useContext, useLayoutEffect } from "react";

import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";
import { TarjetaCertificadosComponent } from "./tarjetaCertificadosComponent";
import { fetchLogs, myAxios } from "../../../../utils/fetch";
import CustomSelect from "../../../common/customSelect";
import {
  CUSTOM_SELECT_COLOR_WHITE,
  CUSTOM_SELECT_COLOR_BLUE,
} from "../../../common/customSelect/constants";
import useFamilyGroup from "./hooks/useFamilyGroup";

const yearsList = (() => {
  const now = new Date();
  const year = now.getFullYear();
  const listaAnos = [{ id: "Todos", name: "Todos" }];
  const anos = year - 2017;

  for (var i = 0; i <= anos; i++) {
    listaAnos.push({ id: year - i, name: year - i });
  }

  return listaAnos;
})();

const CertificadosDeAsistenciaComponent = () => {
  useLayoutEffect(() => {
    fetchLogs({
      idLogType: 1,
      idLog: 10,
      source: "hb-online",
      email: user.user.data.Customers.email,
      dni: user.user.data.Customers.dni ?? "",
      credencial: user.user.data.Customers.credencial ?? "",
      description: `El usuario ${user.user.data.Customers.lastName} ${user.user.data.Customers.firstName} 
        Ingreso al Área de Certificados de Asistencia`
    });
  }, [])
  
  const { user } = useContext(UserGlobalContextMemorySpace);
  const [loader, setLoader] = React.useState(true);
  const [results, setResults] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const [closeAno, setCloseAno] = React.useState(false);
  const [year, setYear] = React.useState("Todos");
  const [idSap, setIdSap] = React.useState(user.user.data.Customers.idSap);
  const controller = new AbortController();

  const [closeFamily, setCloseFamily] = React.useState(false);
  const { familyGroupList } = useFamilyGroup(null);
  const [familyItem, setFamilyItem] = React.useState(
    user.user.data.Customers.firstName + " " + user.user.data.Customers.lastName
  );

  const siguiente = () => {
    if (offset + limit > total) return;

    setOffset(offset + limit);
  };

  const anterior = () => {
    const temporalOffset = offset - limit;
    if (temporalOffset < 0) return;

    setOffset(temporalOffset);
  };

  const descargar = (resultado) => {
    const payLoad = {
      doc: resultado.requestObject,
      idSap: idSap,
      idCustomer: user.user.data.Customers.idCustomer,
    };

    myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/getattendancecertificatefile",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      params: payLoad,
    })
      .then((response) => {
        try {
          fetchLogs({
            idLogType: 1,
            idLog: 24,
            source: "hb-online",
            email: user.user.data.Customers.email,
            dni: user.user.data.Customers.dni ?? "",
            credencial: user.user.data.Customers.credencial ?? "",
            description:
              "El usuario " +
              user.user.data.Customers.lastName +
              " " +
              user.user.data.Customers.firstName +
              " descargó el documento " +
              resultado.name +
              " de tipo " +
              resultado.type,
          });
        } catch (logError) {
          console.error("Error al descargar documento:", logError);
        }

        const isSafariOnIOS = () => {
          const userAgent = navigator.userAgent;
          return (
            /iPhone|iPad|iPod/.test(userAgent) &&
            !/CriOS/.test(userAgent) &&
            /Safari/.test(userAgent)
          );
        };

        if (isSafariOnIOS()) {
          const byteCharacters = atob(response.data.data.File);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });

          // Crear un objeto URL para el blob
          const url = window.URL.createObjectURL(blob);

          // Crear un enlace y simular un clic para descargar el archivo
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.setAttribute(
            "download",
            `resultado_${resultado.idLaboratory}.pdf`
          );
          document.body.appendChild(downloadLink);
          downloadLink.download = `resultado_${resultado.idLaboratory}.pdf`;
          downloadLink.click();

          // Liberar el objeto URL creado
          window.URL.revokeObjectURL(url);
        } else {
          const linkSource = `data:application/pdf;base64,${response.data.data.file}`;
          const downloadLink = document.createElement("a");
          const fileName = resultado.name + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const enviarMail = (resultado) => {
    const payLoad = {
      doc: resultado.requestObject,
      idSap: idSap,
      idCustomer: user.user.data.Customers.idCustomer,
    };

    if (navigator.canShare) {
      myAxios({
        signal: controller.signal,
        url:
          process.env.REACT_APP_GATEWAY_QA +
          "/customers/api/v1/getattendancecertificatefile",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          USERTOKEN: user.user.data.Token,
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
        params: payLoad,
      })
        .then(async (response) => {
          const fresponse = await fetch(
            `data:application/pdf;base64,${response.data.data.file}`
          );
          const buffer = await fresponse.arrayBuffer();

          const pdf = new File(
            [buffer],
            `Certificado de Asistencia ${resultado.name} ${resultado.date.slice(
              0,
              10
            )}.pdf`,
            { type: "application/pdf" }
          );
          const files = [pdf];
          // Share PDF file if supported.
          if (navigator.canShare({ files })) {
            await navigator.share({ files: files });
          }
        })
        .catch((error) => {})
        .finally(() => {});
    } else {
      const asunto = encodeURIComponent("Asunto del correo");
      const cuerpo = encodeURIComponent("Contenido del correo.");

      const mailtoLink = `mailto:?subject=${asunto}&body=${cuerpo}`;
      window.location.href = mailtoLink;
    }
  };

  const obtenerResultados = (idSap, limit, offset, year) => {
    setResults([]);
    setLoader(true);
    const payLoad = {
      idSap,
      limit,
      offset,
      year,
      idCustomer: user.user.data.Customers.idCustomer,
    };

    myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/getattendancecertificates",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      params: payLoad,
    })
      .then((response) => {
        setResults(response.data.data.certificates);
        setTotal(response.data.data.total);
        setLoader(false);
      })
      .catch((error) => {
        setResults([]);
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const changeidSap = (id, name, text) => {
    setFamilyItem(text);
    setOffset(0);
    setIdSap(id);
    obtenerResultados(id, limit, offset, year);
  };

  const onChangeYear = (id, name, text) => {
    setYear(id);
    setOffset(0);
    obtenerResultados(idSap, limit, offset, id);
  };

  const clickIdSap = () => {
    setCloseFamily(false);
    //setCloseAno(true);
  };

  React.useEffect(() => {
    obtenerResultados(idSap, limit, offset, year);
    setLoader(false);
  }, []);

  React.useEffect(() => {
    obtenerResultados(idSap, limit, offset, year);
  }, [offset]);

  function formatDate(dateString) {
    if (!dateString || dateString.length !== 3) {
      return null;
    }
    return `${dateString[2]}-${dateString[1]}-${dateString[0]}`;
  }

  const Desktop = () => {
    return (
      <>
        {/* SEPARADOR */}
        <div className="separador-table-red-result"></div>
        {/* TABLA */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="table-responsive">
              <table
                className="table table-hb table-pshb-result"
                style={{ color: "#05294F" }}
              >
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Tipo de Documento</th>
                    <th>Servicio</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!loader ? (
                    results.map((result) => {
                      return (
                        <tr key={result.date}>
                          <td>
                            {formatDate(result.date.slice(0, 10).split("-"))}
                          </td>
                          <td>{result.type}</td>
                          <td>{result.name}</td>
                          <td>
                            <img
                              className="pointer"
                              style={{ marginRight: "20px" }}
                              onClick={() => descargar(result)}
                              src={DownloadIcon}
                              alt="..."
                            ></img>
                            <img
                              className="pointer"
                              onClick={() => enviarMail(result)}
                              src={MailIcon}
                              alt="..."
                            ></img>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colspan="5" style={{ textAlign: "center" }}>
                        <span className="spinner-grow spinner-grow-sm"></span>
                        <span className="spinner-grow spinner-grow-sm"></span>
                        <span className="spinner-grow spinner-grow-sm"></span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* SEPARADOR */}
        <div className="separador-table-red-result"></div>
        {total > 0 ? (
          <div id="paginate">
            {(offset > 0) & !loader ? (
              <div
                className="btn btn-hb-primary-without-border shadow-blue"
                onClick={anterior}
              >
                Anterior
              </div>
            ) : (
              <></>
            )}
            {!loader & results.length ? (
              <span className="number-hb-paginate">
                <a href="#">{offset / 5 + 1}</a>
              </span>
            ) : (
              <></>
            )}
            {(offset + results.length < total) & results.length & !loader ? (
              <div className="btn btn-red-without-border" onClick={siguiente}>
                Siguiente
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const Mobile = () => {
    return (
      <>
        {/* <div className="separador-table-red-result"></div> */}
        <div className="mt-2">
          {results.map((result) => (
            <TarjetaCertificadosComponent
              key={result.date}
              result={result}
              eventDownload={descargar}
              eventMail={enviarMail}
            />
          ))}
        </div>
        {total > 0 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {!loader ? (
              <div
                className="btn btn-red-without-border"
                onClick={offset === 0 ? null : anterior}
                style={
                  offset === 0
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
              >
                Anterior
              </div>
            ) : (
              <></>
            )}
            {!loader ? (
              <span className="number-hb-paginate">
                <a href="#">
                  {offset / 5 + 1} de {Math.ceil(total / 5)}
                </a>
              </span>
            ) : (
              <></>
            )}
            {!loader ? (
              <div
                className="btn btn-red-without-border"
                onClick={
                  offset / 5 + 1 === Math.ceil(total / 5) ? null : siguiente
                }
                style={
                  offset / 5 + 1 === Math.ceil(total / 5)
                    ? { visibility: "hidden" }
                    : { visibility: "visible" }
                }
              >
                Siguiente
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <div className="family-group-pshb ">
      <section className=" ">
        <div className="container p-4 mt-5 container_mobiles">
          {/* TITULO */}
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <h2 className="section_hb_title">
                <b>Mis certificados de Asistencia</b>
              </h2>
              <p className="section_hb_subtitle" style={{ marginBottom: 10 }}>
                En esta sección podrás descargar el certificado de asistencia.
              </p>
            </div>
            <div
              className="item-2 col-years-results mt-auto"
              style={{ height: 42 }}
            >
              <div id="title-study-results" style={{ width: "112px" }}>
                <span className="año-estudio">Año del estudio</span>
              </div>
              <div style={{ position: "relative", width: "111px" }}>
                <CustomSelect
                  close={closeAno}
                  defaultText={year}
                  optionsList={yearsList}
                  type={CUSTOM_SELECT_COLOR_WHITE}
                  onChange={onChangeYear}
                  // onClick={clickYear}
                  width="111px"
                  name="year"
                  paddingTop="1rem"
                  fontFamily="Roboto-Regular"
                />
              </div>
            </div>
            <div className="item-3 mt-auto search-patiente-asistencia">
              <div
                id="dropdown-name"
                style={{ position: "relative", maxWidth: "250px" }}
              >
                <CustomSelect
                  defaultText={familyItem}
                  optionsList={familyGroupList ?? [{ id: "", name: "" }]}
                  type={CUSTOM_SELECT_COLOR_BLUE}
                  width="100%"
                  name="family"
                  onChange={changeidSap}
                  onClick={clickIdSap}
                  fontFamily="Roboto-Regular"
                  close={closeFamily}
                />
              </div>
            </div>
          </div>
          <div class="d-block d-md-none w-100">
            <div className="separador-table-red-result"></div>
            {!loader ? (
              results.length > 0 ? (
                <Mobile />
              ) : (
                <>
                  <p
                    className="section_hb_title"
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      paddingBottom: "15px",
                      paddingTop: "15px",
                    }}
                  >
                    No se encontraron resultados con esas opciones de búsqueda.
                    <br /> Intente con otros parámetros, o contáctese con
                    nuestro equipo de{" "}
                    <a
                      href="mailto:soportewebhb@hbritanico.com.ar"
                      className="section_hb_title"
                      style={{
                        fontSize: "16px",
                        textDecoration: "underline",
                      }}
                    >
                      Soporte
                    </a>
                    .
                  </p>
                </>
              )
            ) : (
              <div
                className="text-center"
                style={{ paddingBottom: "15px", marginTop: "50px" }}
              >
                <span className="spinner-grow spinner-grow-sm"></span>
                <span className="spinner-grow spinner-grow-sm"></span>
                <span className="spinner-grow spinner-grow-sm"></span>
              </div>
            )}
          </div>

          {/* Componente para pantallas medianas y grandes (oculto en dispositivos móviles) */}
          <div class="d-none d-md-block w-100">
            <Desktop />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CertificadosDeAsistenciaComponent;
