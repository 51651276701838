import React, { useContext } from "react";
import ViewIcon from "../../../assets/img/card-icons/previsualizar.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendar } from "@fortawesome/free-solid-svg-icons";
import "./refund.scss";
import { useToggle } from "../../../hooks/useToggle";
import { InformacionComponent } from "./informacion";
import InformationIcon from "../../../assets/img/information.svg";
import { RefundViewComponent } from "./view";
import axios from "axios";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { myAxios, fetchLogs } from "../../../utils/fetch";
import useConstructor from "../../../utils/hooks/useConstructor";
import { Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { validateModel } from "./validationSchema";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const RefundComponent = () => {
  const date = new Date();
  const fecha_hasta =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0");
  date.setMonth(date.getMonth() - 6);
  const fecha_desde =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0");

  const refundViewToggle = useToggle();
  const informacionToggle = useToggle();
  const [results, setResults] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [search, setSearch] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const [limit, setLimit] = React.useState(5);
  const [offset, setOffset] = React.useState(0);
  const [desde, setDesde] = React.useState(fecha_desde);
  const [hasta, setHasta] = React.useState(fecha_hasta);
  const [isPrinter, setIsPrinter] = React.useState(false);

  const [errorFechas, setErrorFechas] = React.useState({});

  const [searchParams, setSearchParams] = useSearchParams({});
  const openDialogParams = searchParams.get("open");
  var ventanaImpresion;

  //const [reintegro,setReintegro]=React.useState();

  const { user, setReintegro } = useContext(UserGlobalContextMemorySpace);

  const controller = new AbortController();

  const obtenerResultados = (contrarestar) => {
    //setResults([]);
    setLoader(true);

    myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/psafiliates/api/v1/GetRefoundStatusNewPortal?contra=" +
        user.user.data.Customers.credencial.split(" ")[0] +
        "&fecha_desde=" +
        desde.replaceAll("-", "") +
        "&fecha_hasta=" +
        hasta.replaceAll("-", "") +
        "&limit=" +
        limit +
        "&offset=" +
        contrarestar +
        "&idCustomer=" +
        user.user.data.Customers.idCustomer,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    })
      .then((response) => {
        setResults(response.data.data.ds);
        setTotal(response.data.data.total);
        setLoader(false);
      })
      .catch((error) => {
        setResults([]);
        setLoader(false);
      })
      .finally(() => {});
  };

  useConstructor(() => {
    obtenerResultados(offset);
  });

  const siguiente = () => {
    setOffset(offset + limit);
    obtenerResultados(offset + limit);
  };

  const anterior = () => {
    const temporalOffset = offset - limit;
    if (temporalOffset < 0) {
      setOffset(0);
      obtenerResultados(0);
    } else {
      setOffset(temporalOffset);
      obtenerResultados(temporalOffset);
    }
  };

  const seleccionarFechaBlur = (e) => {};

  const seleccionarFecha = (e) => {
    if (e.target.name === "desde" && e.target.value.length <= 10) {
      setDesde(e.target.value);
    } else if (e.target.name === "hasta" && e.target.value.length <= 10) {
      setHasta(e.target.value);
    }

    let fechaSplit = e.target.value.split("-");

    if (fechaSplit.length !== 3) {
      setErrorFechas({ ...errorFechas, [e.target.name]: "Fecha inválida" });
      return;
    } else {
      delete errorFechas[e.target.name];
      setErrorFechas(errorFechas);
    }

    let fechaSeleccionada = new Date(
      fechaSplit[0],
      fechaSplit[1] - 1,
      fechaSplit[2]
    );

    if (isNaN(fechaSeleccionada)) {
      setErrorFechas({ ...errorFechas, [e.target.name]: "Fecha inválida" });
      return;
    } else {
      delete errorFechas[e.target.name];
      setErrorFechas(errorFechas);
    }

    const current = new Date();

    if (e.target.name === "desde") {
      const fechaMinima = new Date("2020", "00", "01");
      let fechaHasta = hasta.split("-");
      fechaHasta = new Date(fechaHasta[0], fechaHasta[1] - 1, fechaHasta[2]);

      if (fechaSeleccionada < fechaMinima) {
        setErrorFechas({ ...errorFechas, [e.target.name]: "Fecha inválida" });
        return;
      } else {
        delete errorFechas[e.target.name];
        setErrorFechas(errorFechas);
      }
      if (fechaSeleccionada > current) {
        setErrorFechas({ ...errorFechas, [e.target.name]: "Fecha inválida" });
        return;
      } else {
        delete errorFechas[e.target.name];
        setErrorFechas(errorFechas);
      }

      if (fechaSeleccionada > fechaHasta) {
        setErrorFechas({ ...errorFechas, [e.target.name]: "Fecha inválida" });
        return;
      } else {
        delete errorFechas[e.target.name];
        setErrorFechas(errorFechas);
      }
    }

    if (e.target.name === "hasta") {
      if (fechaSeleccionada > current) {
        setErrorFechas({ ...errorFechas, [e.target.name]: "Fecha inválida" });
        return;
      } else {
        delete errorFechas[e.target.name];
        setErrorFechas(errorFechas);
      }

      let fechaDesde = desde.split("-");
      fechaDesde = new Date(fechaDesde[0], fechaDesde[1] - 1, fechaDesde[2]);

      if (fechaSeleccionada < fechaDesde) {
        setErrorFechas({ ...errorFechas, [e.target.name]: "Fecha inválida" });
        return;
      } else {
        delete errorFechas[e.target.name];
        setErrorFechas(errorFechas);
      }
    }

    // let anoSplit=parseInt(fechaSplit[0]);

    // if(fechaSplit?.length===3)
    // {
    //     if(anoSplit>2100)
    //     {
    //         e.target.value="1900-"+fechaSplit[1]+"-"+fechaSplit[2];
    //     }
    // }
  };

  // React.useEffect(()=>{

  // },[desde,hasta])

  React.useEffect(() => {
    if (openDialogParams == "1") {
      informacionToggle.onToggle();
    }
    try {
      fetchLogs({
        idLogType: 100,
        idLog: 200,
        source: "hb-online",
        email: user.user.data.Customers.email,
        dni: user.user.data.Customers.dni ?? "",
        credencial: user.user.data.Customers.credencial ?? "",
        description:
          "El usuario " +
          user.user.data.Customers.lastName +
          " " +
          user.user.data.Customers.firstName +
          " accedio al area de reintegros.",
      });
    } catch (logError) {
      console.error("Error al acceder al área de reintegros:", logError);
    }
  }, []);

  const buscarReintegro = (rein, sucur) => {
    myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/psafiliates/api/v1/GetRefoundDetail?contra=" +
        user.user.data.Customers.credencial.split(" ")[0] +
        "&rein_number=" +
        rein +
        "&sucursal=" +
        sucur +
        "&idCustomer=" +
        user.user.data.Customers.idCustomer,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    })
      .then((response) => {
        setReintegro({ ...response.data.data.ds[0], nro_recibo: rein });
        refundViewToggle.onToggle();
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const imprimir = () => {
    myAxios({
      signal: controller.signal,
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/psafiliates/api/v1/GetRefoundStatusNewPortal?contra=" +
        user.user.data.Customers.credencial.split(" ")[0] +
        "&fecha_desde=" +
        desde.replaceAll("-", "") +
        "&fecha_hasta=" +
        hasta.replaceAll("-", "") +
        "&limit=" +
        10000000 +
        "&offset=" +
        0 +
        "&idCustomer=" +
        user.user.data.Customers.idCustomer,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    })
      .then((response) => {
        setIsPrinter(true);
        let temporalDate = Date();
        ventanaImpresion = window.open("", temporalDate);
        var template = `
                <table style="width:100%;text-align:justify">
                  <tr>
                   <th>N° Reintegro</th><th>Reintegro por Discapacidad</th><th>Estado</th><th>Fecha</th>
                  </tr>            
            `;

        response.data.data.ds.forEach((result) => {
          template += `
                <tr>
                    <td>${result.rein}</td>
                    <td>${result.sucur === 14 ? "SI" : "NO"}</td>
                    <td>${result.estado}</td>
                    <td>${result?.alta_fecha?.substring(0, 10)}</td>
                </tr>
                `;
        });

        template += `</table`;
        ventanaImpresion.document.write("");
        ventanaImpresion.document.write(template);
        ventanaImpresion.print();
        ventanaImpresion.focus();
        setIsPrinter(false);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const buscarPorFecha = () => {
    if (desde && hasta) {
      setOffset(0);
      obtenerResultados(0);
    }
  };

  return (
    <section className=" ">
      <div className="container p-4">
        {/* TITULO */}
        <div className="row mt-5">
          <div
            className="col-lg-12"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <h2 className="section_pshb_title_1">
                <b>Reintegros: Estado de la Consulta</b>
              </h2>
            </div>
            <a data-tooltip-id="my-tooltip" data-tooltip-content="Ayuda">
              <ReactTooltip id="my-tooltip" />{" "}
              <img
                id="icon-information"
                onClick={() => informacionToggle.onToggle()}
                src={InformationIcon}
                alt="..."
                style={{ height: "28px" }}
              ></img>
            </a>
          </div>
        </div>

        {/* <div className="row">
                  <div className="col-lg-12" id="header-invoice">                                        
                        <span className="section_pshb_subtitle">
                           
                        </span>                                     
                        <img id="icon-information" src={InformationIcon} alt="..."></img>                         
                  </div>                                      
                </div> */}

        <div className="separador-table-green-result"></div>

        <div className="card card-refund b-radius-20 shadow-external">
          <div className="g-0">
            <div className="sections-date row">
              <div className="section-date col-md-4 me-3">
                <span className="label-pshb">Desde</span>

                <div className="input-icons-date-pshb me-3 flex-column">
                  {/* <FontAwesomeIcon icon={faCalendar} className="custom-icon-results-date-pshb"/> */}

                  <input
                    type="date"
                    maxLength={10}
                    value={desde}
                    class="pt-1 input-round shadow-blue w-100 input-date-ios"
                    name="desde"
                    onBlur={seleccionarFechaBlur}
                    onChange={seleccionarFecha}
                    placeholder="Elegir fecha"
                  />
                  {"desde" in errorFechas ? (
                    <span style={{ color: "red" }}>{errorFechas.desde}</span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="section-date col-md-4">
                <span className="label-pshb">Hasta</span>

                <div className="input-icons-date-pshb me-3 flex-column">
                  {/* <FontAwesomeIcon icon={faCalendar} className="custom-icon-results-date-pshb"/> */}

                  <input
                    type="date"
                    name="hasta"
                    value={hasta}
                    onChange={seleccionarFecha}
                    class="pt-1 input-round shadow-blue w-100 input-date-ios"
                    placeholder="Elegir fecha"
                  />

                  {"hasta" in errorFechas ? (
                    <span style={{ color: "red" }}>{errorFechas.hasta}</span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <Button
                  type="button"
                  disabled={Object.keys(errorFechas).length}
                  onClick={() => buscarPorFecha()}
                  style={{ marginTop: "30px", marginLeft: "0px" }}
                  className="btn btn-search btn-hb-primary-without-border shadow-blue"
                >
                  Buscar
                </Button>
              </div>
            </div>

            <div className="separador-table-green-result"></div>

            <div className="section-search-and-print">
              <div className="input-icons-pshb me-3">
                {/* <FontAwesomeIcon icon={faSearch} className="custom-icon-results-pshb"/>
                            <input className="input-field-results-pshb" 
                                type="text" 
                                placeholder="Buscar"/> */}
              </div>
              <button
                class="btn btn-pshb-secondary-without-border shadow-blue"
                disabled={isPrinter}
                onClick={results?.length > 0 ? imprimir : null}
              >
                Imprimir
              </button>
            </div>

            {/* SEPARADOR */}
            <div className="separador-table-green-result-slim"></div>
            {/* TABLA */}
            <RefundViewComponent
              visible={refundViewToggle.toggle}
              onToggle={refundViewToggle.onToggle}
            />

            <InformacionComponent
              visible={informacionToggle.toggle}
              onToggle={informacionToggle.onToggle}
            />

            <div className="row mt-3">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <table className="table table-pshb">
                    <thead>
                      <tr>
                        <th>N° Registro</th>
                        <th>Reintegro por Discapacidad</th>
                        <th>Estado</th>
                        <th>Fecha</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr>
                                            <td>1</td>
                                            <td>No</td>                                        
                                            <td>Aprobado</td>                                        
                                            <td>2022-08-09</td>                                        
                                            <td>
                                                <img className="i-25" src={ViewActiveIcon} onClick={refundViewToggle.onToggle} alt="..."></img>                                                 
                                            </td>
                                        </tr>      */}

                      {!loader ? (
                        results &&
                        results.map((result) => {
                          return (
                            <tr key={result.rein}>
                              <td>{result.rein}</td>
                              <td>{result.sucur === 14 ? "SI" : "NO"}</td>
                              <td>{result.estado}</td>
                              <td>{result?.alta_fecha?.substring(0, 10)}</td>
                              <td>
                                <img
                                  className="i-25"
                                  src={ViewIcon}
                                  onClick={() =>
                                    buscarReintegro(result.rein, result.sucur)
                                  }
                                  alt="..."
                                ></img>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            <span className="spinner-grow spinner-grow-sm"></span>
                            <span className="spinner-grow spinner-grow-sm"></span>
                            <span className="spinner-grow spinner-grow-sm"></span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* SEPARADOR */}
            <div className="separador-table-green-result-slim"></div>

            {total > 0 ? (
              <div id="paginate">
                {(offset > 0) & !loader ? (
                  <div
                    className="btn btn-pshb-primary shadow-blue"
                    onClick={anterior}
                  >
                    Anterior
                  </div>
                ) : (
                  <></>
                )}
                {!loader ? (
                  <span className="number-pshb-paginate">
                    <a href="#">
                      {offset / 5 + 1} de {Math.ceil(total / limit)}
                    </a>
                  </span>
                ) : (
                  <></>
                )}
                {(offset + results?.length < total) & !loader ? (
                  <div
                    className="btn btn-pshb-primary shadow-blue"
                    onClick={siguiente}
                  >
                    Siguiente
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(RefundComponent);
