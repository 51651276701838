import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import TurnosComponent from "../../../components/hb/turnos";
import { fetchLogs } from "../../../utils/fetch";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { PopupValidateSap } from "../../../components/common/popup-validate-sap";

import useValidProfile from "../../../hooks/useValidProfile";

const Turnos = () => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const { user } = useContext(UserGlobalContextMemorySpace);
  const { showPopup } = useValidProfile(user);

  useLayoutEffect(() => {
    try {
      fetchLogs({
        idLogType: 1,
        idLog: 19,
        source: "hb-online",
        email: user.user.data.Customers.email,
        dni: user.user.data.Customers.dni ?? "",
        credencial: user.user.data.Customers.credencial ?? "",
        description:
          "El usuario " +
          user.user.data.Customers.lastName +
          " " +
          user.user.data.Customers.firstName +
          " accedió al área de turnos",
      });
    } catch (logError) {
      console.error("Error al acceder al área de turnos:", logError);
    }
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <div className="recupero-contrasena d-flex justify-content-center align-items-center">
        {showPopup ? (
          <PopupValidateSap message="Para obtener un turno" visible={true} />
        ) : (
          <TurnosComponent />
        )}
      </div>
    </div>
  );
};

export default Turnos;
